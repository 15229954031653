<!--
 * @Description: 车行道停车运营管理 员工管理 排班管理 workForceManagement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2023-01-17 14:08:10
 * @LastEditors: zhoucheng
-->

<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="路段名称">
              <el-select v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="item in allParkingList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="90px"
                          label="日期">
              <el-date-picker v-model="searchDate"
                              type="daterange"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">排班管理</el-col>
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="success"
                     @click="handleAdd">新 增</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             @click="handleDetails(scope.$index, scope.row) "
                             size="medium">详 情</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             @click="handleChanged(scope.$index, scope.row) "
                             size="medium">修 改</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             @click="handleDelete(scope.$index, scope.row) "
                             size="medium">删 除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增排班"
               append-to-body
               :visible.sync="dialogVisible">
      <el-form :model="insterForm"
               :rules="rules"
               ref="insterForm"
               label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="街道/城镇"
                          prop="regionName">
              <el-cascader v-model="insterForm.regionName"
                           :options="options"
                           @change="handleCheck"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路段名称"
                          prop="parkId">
              <el-select v-model="insterForm.parkId"
                         @change="handleParkingChange"
                         placeholder="">
                <el-option v-for="item in parkingList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="选择日期"
                          prop="scheduleDate">
              <el-date-picker v-model="insterForm.scheduleDate"
                              type="date"
                              placeholder="选择日期"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班次名称"
                          prop="shiftCode">
              <el-select v-model="insterForm.shiftCode"
                         placeholder="">
                <el-option v-for="item in shiftList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="排班类型"
                          prop="scheduleCode">
              <el-select v-model="insterForm.scheduleCode"
                         placeholder="">
                <el-option v-for="item in shiftTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费组"
                          prop="inspectionGroup">
              <el-select v-model="insterForm.inspectionGroup"
                         placeholder=""
                         @change="handleInspectionGroup">
                <el-option v-for="item in processInspectorGroup"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="收费员"
                          prop="userCode">
              <el-select v-model="insterForm.userCode"
                         @change="userChange"
                         placeholder="">
                <el-option v-for="item in processInspector"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-button type="primary"
                     class="dt-dialogButton"
                     @click="handleSubmit('insterForm')">保存</el-button>
        </el-row>

      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="排班修改"
               append-to-body
               @close="popClose"
               :visible.sync="changesVisible">
      <el-form :model="changesForm"
               :rules="changeRules"
               ref="changesForm"
               label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="街道/城镇">
              <el-input v-model="changesForm.regionName"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路段名称">
              <el-input v-model="changesForm.parkName"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dt-spacing">
          <el-col :span="12">
            <el-form-item label="日期">
              <el-input v-model="changesForm.scheduleDate"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班次类型">
              <el-select v-model="changesForm.scheduleName"
                         disabled
                         placeholder="">
                <el-option v-for="item in shiftTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dt-spacing">
          <el-col :span="12">
            <el-form-item label="班次名称">
              <el-select v-model="changesForm.shiftCode"
                         disabled
                         placeholder="">
                <el-option v-for="item in shiftList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费组">
              <el-select v-model="changesForm.inspectionGroupName"
                         placeholder=""
                         disabled
                         @change="handleInspectionGroup">
                <el-option v-for="item in processInspectorGroup"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dt-spacing">
          <el-col :span="12">
            <el-form-item label="收费员">
              <el-select v-model="changesForm.userCode"
                         @change="userChange"
                         placeholder="">
                <el-option v-for="item in processInspector"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-button type="primary"
                     class="dt-dialogButton"
                     @click="handleSave('changesForm')">保存</el-button>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="排班详情"
               append-to-body
               :visible.sync="InspectorVisible">
      <el-form :model="inspectorForm"
               ref="inspectorForm"
               label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="街道/城镇"
                          prop="addres">
              <el-select v-model="inspectorForm.regionName"
                         disabled
                         placeholder="">
                <!-- <el-option v-for="item in adddresOptions"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班次">
              <el-select v-model="inspectorForm.shiftName"
                         disabled
                         placeholder="">
                <!-- <el-option v-for="item in groupsOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="路段名称">
              <el-select v-model="inspectorForm.parkName"
                         disabled
                         placeholder="">
                <!-- <el-option v-for="item in groupsOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班次类型">
              <el-select v-model="inspectorForm.scheduleName"
                         disabled
                         placeholder="">
                <!-- <el-option v-for="item in peopleOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="日期">
              <el-date-picker v-model="inspectorForm.scheduleDate"
                              disabled
                              type="date"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费组">
              <el-select v-model="inspectorForm.inspectionGroupName"
                         disabled>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-form-item label="收费员">
              <el-select v-model="inspectorForm.userName"
                         disabled>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-button type="primary"
                     class="dt-dialogButton"
                     @click="InspectorVisible=false">返 回</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableColumnList: [
        {
          prop: 'regionName',
          label: '街道/城镇',
        },
        {
          prop: 'parkName',
          label: '路段名称',
        },
        {
          prop: 'scheduleDate',
          label: '日期',
        },
        {
          prop: 'scheduleName',
          label: '班次类型'
        },
        {
          prop: 'shiftName',
          label: '班次名称'
        },
        {
          prop: 'userName',
          label: '巡检员'
        }
      ],
      tableList: {
        list: [],
      },
      searchForm: {},
      pageSize: 15,
      pageNum: 1,
      parkingLotInformation: [],
      dialogVisible: false,// 新增弹出框
      changesVisible: false,// 修改弹出框
      InspectorVisible: false,// 查看弹出窗 
      insterForm: {
        userCode: '',
        parkId: ''
      },//新增班次
      changesForm: {},//修改表单
      inspectorForm: {},//选择巡检员
      adddresOptions: [],//行政区域
      shiftList: [],//班次例表
      shiftTypeList: [],//排班类型列表
      processInspector: [],//巡检员列表
      processInspectorGroup: [],//巡检组列表

      groupsOptions: [],
      peopleOptions: [],
      options: [],//行政区域-路段级联
      parkingList: [],//停车场列表
      allParkingList: [],//所有停车场
      searchDate: '',//搜索时间
      rules: {
        regionName: [{ required: true, message: '请选择', trigger: 'change' }],
        parkId: [{ required: true, message: '请选择', trigger: 'change' }],
        scheduleDate: [{ required: true, message: '请选择日期', trigger: 'change' }],
        scheduleCode: [{ required: true, message: '请选择', trigger: 'change' }],
        shiftCode: [{ required: true, message: '请选择', trigger: 'change' }],
        userCode: [{ required: true, message: '请选择', trigger: 'change' }],
        inspectionGroup: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      changeRules: {
        shiftCode: [{ required: true, message: '请选择', trigger: 'change' }],
        scheduleCode: [{ required: true, message: '请选择', trigger: 'change' }],
        userCode: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryTableList()
    this.getAdministrative()
    this.getShift()
    this.getShiftType()
    this.getCharge()
    this.getAllparkingList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  //方法集合
  methods: {
    // 查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$workForceManagement.queryInspectionSchedule(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询按钮
    onSubmit () {
      if (this.searchDate.length > 0) {
        this.searchForm.startTime = this.searchDate[0]
        this.searchForm.endTime = this.searchDate[1]
      }
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.pageNum = 1
      this.searchDate = []
      this.searchForm = {}
      this.queryTableList()
    },
    // 获取行政区域
    getAdministrative () {
      let info = {
        "columnName": ["region_code", "region_name"],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then(res => {
        let area = res.resultEntity
        for (let index in area) {
          let info = {
            "columnName": ["road_code", "road_name"],
            "tableName": "tb_road",
            "whereStr": [
              {
                "colName": "region_code",
                "value": area[index].code  //联动查询，这个传选的行政区code
              }
            ]
          }
          this.$queryDict.queryDict(info).then((res) => {
            let response = res.resultEntity
            let roadList = []
            for (let index in response) {
              roadList.push({ value: response[index].code, label: response[index].name })
            }
            let data = {
              value: area[index].code,
              label: area[index].name,
              children: roadList
            }
            this.options.push(data)
          })
        }
      })
    },
    //获取班次
    getShift () {
      let info = {
        "columnName": ["shift_id", "shift_name"],
        "tableName": "tb_shift"
      }
      this.$queryDict.queryDict(info).then(res => {
        this.shiftList = res.resultEntity
      })
    },
    //获取班次类型
    getShiftType () {
      let info =
      {
        "columnName": ["value", "name"],
        "tableName": "tb_dict",
        "whereStr": [
          {
            "colName": "parent_id",
            "value": "889CBA0E4A6B48898859501A2BB20356"
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.shiftTypeList = res.resultEntity
      })
    },

    //获取收费员
    getCharge () {
      let info = {
        "columnName": ["user_code", "user_name"],
        "tableName": "tb_account_info",
      }
      this.$queryDict.queryDict(info).then(res => {
        this.processInspector = res.resultEntity
      })
    },

    //新增
    handleAdd () {
      this.dialogVisible = true
    },
    //新增保存
    handleSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.insterForm.regionName = this.insterForm.regionName[0]
          this.$workForceManagement.insertInspectionSchedule(this.insterForm).then(() => {
            this.$message({ message: '新增成功', type: 'success' });
            this.queryTableList()
            this.dialogVisible = false
          })
        } else {
          return false;
        }
      });
    },
    //修改保存
    handleSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$workForceManagement.updateInspectionSchedule(this.changesForm).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.changesVisible = false
            this.queryTableList()
          })
        } else {
          return false;
        }
      });
    },
    //详情
    handleDetails (index, row) {
      this.inspectorForm = row
      this.InspectorVisible = true
    },
    //修改
    handleChanged (index, row) {
      this.processInspectorGroup = []
      this.changesForm = this.$deepClone(row)
      //根据区域查收费组
      let groupInfo =
      {
        "columnName": [
          "inspection_group_id",
          "inspection_group_name"
        ],
        "tableName": "tb_inspection_group",
        "whereStr": [
          {
            "colName": "region_code",
            "value": row.regionCode //这里是街道城镇的code
          }
        ]
      }
      this.$queryDict.queryDict(groupInfo).then(res => {
        this.processInspectorGroup = res.resultEntity
      })
      this.changesVisible = true
    },
    //删除
    handleDelete (index, row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$workForceManagement.deleteInspectionSchedule([{ scheduleSequence: row.scheduleSequence }]).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.queryTableList()
        })
      })
    },

    //行政区域选择路段
    handleCheck (val) {
      let region = val[0]
      this.parkingList = []
      this.processInspectorGroup = []
      this.processInspector = []
      this.insterForm.userCode = ''
      this.insterForm.parkId = ''
      this.insterForm.inspectionGroup = ''

      //根据区域查停车场
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 1 //这个是停车场类型 1是路内2是路外
          },
          {
            "colName": "road_code",
            "value": val[1] //这个是路段的code
          },
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.parkingList = res.resultEntity
      })

      //根据区域查收费组
      let groupInfo =
      {
        "columnName": [
          "inspection_group_id",
          "inspection_group_name"
        ],
        "tableName": "tb_inspection_group",
        "whereStr": [
          {
            "colName": "region_code",
            "value": region //这里是街道城镇的code
          }
        ]
      }
      this.$queryDict.queryDict(groupInfo).then(res => {
        this.processInspectorGroup = res.resultEntity
      })
    },
    //停车场发生改变
    handleParkingChange (val) {
      this.insterForm.parkId = val
      this.$forceUpdate()
    },
    //巡检组发生变化
    handleInspectionGroup (val) {
      this.insterForm.inspectionGroup = val
      this.$forceUpdate()
      let info = {
        inspectionGroupId: val
      }
      this.$workForceManagement.queryDictEmployee(info).then(res => {
        this.processInspector = res.resultEntity
      })
    },
    //获取停车场
    getAllparkingList () {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 1 //这个是停车场类型 1是路内2是路外
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.allParkingList = res.resultEntity
      })
    },
    popClose () {
      this.insterForm = {}
      this.$refs.insterForm.resetFields()
    },
    userChange (val) {
      console.log(val);
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    background: #0a2143;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 16px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    background: #0a2143;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-dialogButton {
  margin-top: 20px;
  // display: flex;
  // justify-content: flex-end;
}
.el-date-editor {
  border: none;
}
.dt-spacing {
  margin-top: 20px;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>

